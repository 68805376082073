import { all, takeLatest } from 'redux-saga/effects'
import sagaRunning from '../../utils/sagaRunning'
import { TYPES } from '../actions'

import {
  getAgendaGroupId,
  addAgendas,
  updateAgendaId,
  deleteAgendaId,
  addComment,
  getCommentsId
} from '../../api/agenda'

export default function* watcher () {
  yield all([
    takeLatest(TYPES.GET_AGENDA_GROUP_ID, sagaRunning({
      api: getAgendaGroupId
    })),
    takeLatest(TYPES.ADD_AGENDAS, sagaRunning({
      api: addAgendas
    })),
    takeLatest(TYPES.UPDATE_AGENDA_ID, sagaRunning({
      api: updateAgendaId
    })),
    takeLatest(TYPES.DELETE_AGENDA_ID, sagaRunning({
      api: deleteAgendaId
    })),
    takeLatest(TYPES.ADD_COMMENT, sagaRunning({
      api: addComment
    })),
    takeLatest(TYPES.GET_COMMENTS_ID, sagaRunning({
      api: getCommentsId
    }))
  ])
}




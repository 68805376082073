import axios from 'axios'
import LocalStorage from '../utils/storage'

const handleAxiosApi = () => {
  axios.defaults.withCredentials = true
  axios.interceptors.response.use((response) => {
    if (response && response.status === 401) {
      LocalStorage.remove('IS_AUTHENTICATION')
      LocalStorage.remove('INFO_USER')
      return response
    }
    return response
  }, function (error) {
    return Promise.reject(error.response)
  })
}

export {
  handleAxiosApi
}

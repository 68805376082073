import { TYPES } from '../actions'

const INIT_STATE = {
  loaded: [],
  submitting: null,
  submitBooking: null,
  error: null,

  username: null,
  permissions: [],
  roleName: null,
  infoUser: {},
  parent: null,
  students: null,
  teacher: null,
  timeBooking: [],
  infoCalendarFreeTime: []
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TYPES.LOGIN_CUSTOMER_REQUEST:
    case TYPES.LOGIN_TEACHER_REQUEST:
    case TYPES.REGISTER_REQUEST:
    case TYPES.GET_INFO_FIRST_LOAD_REQUEST:
    case TYPES.LOGOUT_REQUEST:
    case TYPES.GET_CUSTOMER_INFO_REQUEST:
    case TYPES.TEACHER_JOIN_US_REQUEST:
    case TYPES.CHANGE_PASSWORD_CUSTOMER_REQUEST:
    case TYPES.CHANGE_CUSTOMER_INFO_REQUEST:
    case TYPES.CHANGE_STUDENT_INFO_REQUEST:
    case TYPES.CHOOSE_AVATAR_REQUEST:
    case TYPES.CREATE_APPOINTMENT_ONLINE_REQUEST:
    case TYPES.GET_TEACHER_INFO_REQUEST:
    case TYPES.CHANGE_PASSWORD_TEACHER_REQUEST:
    case TYPES.CHANGE_TEACHER_INFO_REQUEST:
    case TYPES.HANDLE_SEND_MAIL_REQUEST:
    case TYPES.RESET_PASSWORD_REQUEST:
    case TYPES.CHECK_EMAIL_REQUEST:
    case TYPES.GET_TIME_BOOKING_REQUEST:
    case TYPES.SEND_MAIL_REGISTER_REQUEST:
    case TYPES.GOOGLE_ACCOUNT_IS_EXISTED_SUCCESS_REQUEST:
      return {
        ...state,
        submitting: action.type,
        submitBooking: action.type
      }
    case TYPES.GET_CALENDAR_APPOINTMENTS_REQUEST: {
      return {
        ...state
      }
    }
    case TYPES.LOGIN_CUSTOMER_SUCCESS:
      return {
        ...state,
        submitting: null
      }
    case TYPES.SEND_MAIL_REGISTER_SUCCESS:
      return {
        ...state,
        submitting: null
      }
    case TYPES.LOGIN_TEACHER_SUCCESS:
      return {
        ...state,
        submitting: null
      }
    case TYPES.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        submitting: null
      }

    case TYPES.HANDLE_SEND_MAIL_SUCCESS:
      return {
        ...state,
        submitting: null
      }
    case TYPES.TEACHER_JOIN_US_SUCCESS:
      return {
        ...state,
        submitting: null
      }
    case TYPES.REGISTER_SUCCESS:
      return {
        ...state,
        submitting: null,
        isCreate: false
      }

    case TYPES.GET_INFO_FIRST_LOAD_SUCCESS:
      return {
        ...state,
        submitting: null,
        infoUser: action.data
      }

    case TYPES.GET_TIME_BOOKING_SUCCESS:
      return {
        ...state,
        submitting: null,
        submitBooking: null,
        timeBooking: action.data
      }

    case TYPES.LOGOUT_SUCCESS:
      return {
        ...state,
        submitting: null,

        infoUser: {},
        parent: null,
        students: null
      }
    case TYPES.LOGOUT_FAILURE:
    case TYPES.GET_INFO_FIRST_LOAD_FAILURE:
    case TYPES.GET_CUSTOMER_INFO_SUCCESS:
      return {
        ...state,
        submitting: null,

        parent: action.data && action.data.parent,
        students: action.data && action.data.students
      }
    case TYPES.CHANGE_PASSWORD_CUSTOMER_SUCCESS:
    case TYPES.CHANGE_PASSWORD_TEACHER_SUCCESS:
      return {
        ...state,
        submitting: null
      }

    case TYPES.CREATE_APPOINTMENT_ONLINE_SUCCESS:
      return {
        ...state,
        submitting: null
      }

    case TYPES.GET_CALENDAR_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        infoCalendarFreeTime: action.data
      }

    case TYPES.CHANGE_CUSTOMER_INFO_SUCCESS:
    case TYPES.CHANGE_TEACHER_INFO_SUCCESS:
      return {
        ...state,
        submitting: null
      }
    case TYPES.CHANGE_STUDENT_INFO_SUCCESS:
      return {
        ...state,
        submitting: null
      }
    case TYPES.CHOOSE_AVATAR_SUCCESS:
      return {
        ...state,
        submitting: null
      }
    case TYPES.GET_TEACHER_INFO_SUCCESS:
      return {
        ...state,
        submitting: null,
        teacher: action.data
      }

    case TYPES.CHECK_EMAIL_SUCCESS:
      return {
        ...state,
        submitting: null
      }
    case TYPES.GOOGLE_ACCOUNT_IS_EXISTED_SUCCESS_SUCCESS:
      return {
        
      }
    case TYPES.GOOGLE_ACCOUNT_IS_EXISTED_SUCCESS_FAILURE:
    case TYPES.REGISTER_FAILURE:
    case TYPES.LOGIN_CUSTOMER_FAILURE:
    case TYPES.LOGIN_TEACHER_FAILURE:
    case TYPES.GET_CUSTOMER_INFO_FAILURE:
    case TYPES.TEACHER_JOIN_US_FAILURE:
    case TYPES.CHANGE_PASSWORD_CUSTOMER_FAILURE:
    case TYPES.CHANGE_CUSTOMER_INFO_FAILURE:
    case TYPES.CHANGE_STUDENT_INFO_FAILURE:
    case TYPES.CHOOSE_AVATAR_FAILURE:
    case TYPES.CREATE_APPOINTMENT_ONLINE_FAILURE:
    case TYPES.GET_CALENDAR_APPOINTMENTS_FAILURE:
    case TYPES.GET_TEACHER_INFO_FAILURE:
    case TYPES.CHANGE_PASSWORD_TEACHER_FAILURE:
    case TYPES.CHANGE_TEACHER_INFO_FAILURE:
    case TYPES.HANDLE_SEND_MAIL_FAILURE:
    case TYPES.RESET_PASSWORD_FAILURE:
    case TYPES.CHECK_EMAIL_FAILURE:
    case TYPES.GET_TIME_BOOKING_FAILURE:
    case TYPES.SEND_MAIL_REGISTER_FAILURE:
      return {
        ...state,
        submitting: null,
        submitBooking: null,
        isCreate: false,
        error: action.error
      }
    default:
      return state
  }
}

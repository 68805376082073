import { all, takeLatest } from 'redux-saga/effects'
import sagaRunning from '../../utils/sagaRunning'
import { TYPES } from '../actions'
import {
  addStudent,
  getAllGroup,
  getAllSubjects,
  getSubjectById,
  getGroupById,
  getGroupByUser,
  createContent,
  getAllContentByTeacher,
  deleteContent
} from '../../api/customer'

export default function* watcher () {
  yield all([
    takeLatest(TYPES.ADD_STUDENT, sagaRunning({ api: addStudent })),
    takeLatest(TYPES.GET_ALL_GROUP, sagaRunning({ api: getAllGroup })),
    takeLatest(TYPES.GET_ALL_SUBJECTS, sagaRunning({ api: getAllSubjects })),
    takeLatest(TYPES.GET_SUBJECT_BY_ID, sagaRunning({ api: getSubjectById })),
    takeLatest(TYPES.GET_GROUP_BY_ID, sagaRunning({ api: getGroupById })),
    takeLatest(TYPES.GET_GROUP_BY_USER, sagaRunning({ api: getGroupByUser })),
    takeLatest(TYPES.CREATE_CONTENT, sagaRunning({ api: createContent })),
    takeLatest(TYPES.GET_ALL_CONTENT_BY_TEACHER, sagaRunning({ api: getAllContentByTeacher })),
    takeLatest(TYPES.DELETE_CONTENT, sagaRunning({ api: deleteContent }))
  ])
}

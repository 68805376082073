import i18next from 'i18next'
import en from './en/index.json'
import vi from './vi/index.json'
import { reactI18nextModule } from 'react-i18next'

const allowedLanguages = [ 'en', 'vi' ]

const defaultLng = 'vi'
let lng = defaultLng

const storageLanguage = localStorage.getItem('language')
if (storageLanguage && allowedLanguages.indexOf(storageLanguage) > -1) {
  lng = storageLanguage
}

const resources = {
  en: { translation: en },
  vi: { translation: vi }
}

i18next
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    lng,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

import { MainApi } from './endpoint'

export const addStudent = (payload) =>
  MainApi.post('/user/customers/me/student', payload)
export const getAllGroup = (payload) => MainApi.get('/user/groups', payload)

export const getGroupById = (payload) =>
  MainApi.get(`/user/groups/${payload}`, payload)
export const getGroupByUser = (payload) =>
  MainApi.get('/user/groups/me', payload)
export const getAllSubjects = (payload) =>
  MainApi.get('/user/subjects', payload)
export const getSubjectById = (payload) =>
  MainApi.get(`/user/subjects/${payload.id}`, payload)
export const createContent = (payload) =>
  MainApi.post('/user/contents', payload)
export const getAllContentByTeacher = (payload) =>
  MainApi.get('/user/contents/teacher/mine', payload)
export const deleteContent = (payload) =>
  MainApi.delete(`/user/contents/${payload.id}`, payload)
export const getAllScheduleCalendar = (payload) =>
  MainApi.get('/user/groups/schedules/all', payload)
import { MainApi } from './endpoint'
// import { mockData } from '../utils/tools';

const url = '/user'

export const getAllScheduleStudent = (payload) => MainApi.get(`${url}/groups/schedules`, payload)
// export const getAgendaGroupId = (payload) => MainApi.get(`${url}/agendas/group/${payload}`);
export const getGroupMe = (payload) => MainApi.get(`${url}/groups/${payload}/me`)
export const getNumberCommentStudent = (payload) => MainApi.get(`${url}/notifications?objectName=${payload.type}&groupId=${payload.groupId}`)
export const getCommentGroupId = (payload) => MainApi.get(`${url}/comments/student/get-comments?groupId=${payload.groupId}&studentId=${payload.studentId}`)
export const getAllGroupMe = (payload) => MainApi.get(`${url}/groups/me`, payload)

export const getTransactionHistory = () => MainApi.get(`${url}/payments/transaction-history`)
export const getGroupInfo = ({ groupId }) => MainApi.get(`${url}/groups/${groupId}`)
import { TYPES } from '../actions'

const INIT_STATE = {
  loaded: [],
  submitting: null,
  error: null,

  scheduleList: [],
  agendaList: null,
  groupMe: null,
  numberComment: null,
  comments: null,
  allGroupMe: null,
  transactionHistory: null
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TYPES.GET_ALL_SCHEDULE_STUDENT_REQUEST:
    case TYPES.GET_GROUP_ME_REQUEST:
    case TYPES.GET_NUMBER_COMMENT_STUDENT_REQUEST:
    case TYPES.GET_COMMENT_GROUP_ID_REQUEST:
    case TYPES.GET_ALL_GROUP_ME_REQUEST:
    case TYPES.GET_TRANSACTION_HISTORY_REQUEST:
      return {
        ...state,
        submitting: action.type
      }
    case TYPES.GET_ALL_SCHEDULE_STUDENT_SUCCESS:
      return {
        ...state,
        submitting: null,
        scheduleList: action.data
      }
    case TYPES.GET_GROUP_ME_SUCCESS:
      return {
        ...state,
        submitting: null,
        groupMe: action.data
      }
    case TYPES.GET_NUMBER_COMMENT_STUDENT_SUCCESS:
      return {
        ...state,
        submitting: null,
        numberComment: action.data?.number_notifications
      }
    case TYPES.GET_COMMENT_GROUP_ID_SUCCESS:
      return {
        ...state,
        submitting: null,
        comments: action.data
      }
    case TYPES.GET_ALL_GROUP_ME_SUCCESS:
      return {
        ...state,
        submitting: null,
        allGroupMe: action.data.rows
      }
    case TYPES.GET_TRANSACTION_HISTORY_SUCCESS:
      return {
        ...state,
        submitting: null,
        transactionHistory: action.data.rows
      }
    case TYPES.GET_ALL_SCHEDULE_STUDENT_FAILURE:
    case TYPES.GET_GROUP_ME_FAILURE:
    case TYPES.GET_COMMENT_GROUP_ID_FAILURE:
    case TYPES.GET_ALL_GROUP_ME_FAILURE:
    case TYPES.GET_TRANSACTION_HISTORY_FAILURE:
      return {
        ...state,
        submitting: null,
        error: action.error
      }
    default:
      return state
  }
}

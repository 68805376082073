import { TYPES } from '../actions'

const INIT_STATE = {
  loaded: [],
  submitting: null,
  error: null,

  listNotification: null,
  count: null,
  numberNotification: null
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TYPES.GET_LIST_NOTIFICATION_REQUEST:
    case TYPES.GET_NUMBER_NOTIFICATION_REQUEST:
      return {
        ...state,
        submitting: action.type
      }
    case TYPES.GET_LIST_NOTIFICATION_SUCCESS:
      return {
        ...state,
        submitting: null,

        listNotification: action.data?.rows,
        count: action.data?.count
      }
    case TYPES.GET_NUMBER_NOTIFICATION_SUCCESS:
      return {
        ...state,
        submitting: null,

        numberNotification: action.data?.number_notifications
      }
    case TYPES.GET_LIST_NOTIFICATION_FAILURE:
    case TYPES.GET_NUMBER_NOTIFICATION_FAILURE:
      return {
        ...state,
        submitting: null,
        error: action.error
      }
    default:
      return state
  }
}

import { all, takeLatest, put } from 'redux-saga/effects'
import sagaRunning from '../../utils/sagaRunning'
import { TYPES } from '../actions'
import LocalStorage from '../../utils/storage'

import {
  loginCustomer,
  loginTeacher,
  register,
  getCustomerInfo,
  logout,
  changePasswordCustomer,
  changeCustomerInfo,
  teacherJoinUs,
  chooseAvatar,
  changeStudentInfo,
  createAppointmentOnline,
  getCalendarAppointments,
  getTeacherInfo,
  changePasswordTeacher,
  changeTeacherInfo,
  handleSendMail,
  resetPassword,
  checkEmail,
  getTimeBooking,
  sendMailRegister,
  googleAccountIsExisted
} from '../../api/account'

export default function* watcher () {
  yield all([
    takeLatest(TYPES.GOOGLE_ACCOUNT_IS_EXISTED, sagaRunning({ api: googleAccountIsExisted })),
    takeLatest(TYPES.LOGIN_CUSTOMER, sagaRunning({ api: loginCustomer })),
    takeLatest(TYPES.LOGIN_TEACHER, sagaRunning({ api: loginTeacher })),
    takeLatest(TYPES.REGISTER, sagaRunning({ api: register })),
    takeLatest(TYPES.TEACHER_JOIN_US, sagaRunning({ api: teacherJoinUs })),
    takeLatest(TYPES.GET_INFO_FIRST_LOAD, getInfoFirstLoad),
    takeLatest(TYPES.LOGOUT, sagaRunning({ api: logout })),
    takeLatest(TYPES.GET_CUSTOMER_INFO, sagaRunning({ api: getCustomerInfo })),
    takeLatest(
      TYPES.CHANGE_PASSWORD_CUSTOMER,
      sagaRunning({ api: changePasswordCustomer })
    ),
    takeLatest(
      TYPES.CHANGE_CUSTOMER_INFO,
      sagaRunning({ api: changeCustomerInfo })
    ),
    takeLatest(
      TYPES.CHANGE_STUDENT_INFO,
      sagaRunning({ api: changeStudentInfo })
    ),
    takeLatest(TYPES.CHOOSE_AVATAR, sagaRunning({ api: chooseAvatar })),
    takeLatest(
      TYPES.CREATE_APPOINTMENT_ONLINE,
      sagaRunning({ api: createAppointmentOnline })
    ),
    takeLatest(
      TYPES.GET_CALENDAR_APPOINTMENTS,
      sagaRunning({ api: getCalendarAppointments })
    ),
    takeLatest(TYPES.GET_TEACHER_INFO, sagaRunning({ api: getTeacherInfo })),
    takeLatest(
      TYPES.CHANGE_PASSWORD_TEACHER,
      sagaRunning({ api: changePasswordTeacher })
    ),
    takeLatest(
      TYPES.CHANGE_TEACHER_INFO,
      sagaRunning({ api: changeTeacherInfo })
    ),
    takeLatest(TYPES.HANDLE_SEND_MAIL, sagaRunning({ api: handleSendMail })),
    takeLatest(TYPES.RESET_PASSWORD, sagaRunning({ api: resetPassword })),
    takeLatest(TYPES.CHECK_EMAIL, sagaRunning({ api: checkEmail })),
    takeLatest(TYPES.GET_TIME_BOOKING, sagaRunning({ api: getTimeBooking })),
    takeLatest(TYPES.SEND_MAIL_REGISTER, sagaRunning({ api: sendMailRegister }))
  ])
}

function* getInfoFirstLoad () {
  yield put({ type: TYPES.GET_INFO_FIRST_LOAD_REQUEST })
  const data = LocalStorage.get('INFO_USER')
  if (data) {
    yield put({ type: TYPES.GET_INFO_FIRST_LOAD_SUCCESS, data })
  } else {
    yield put({ type: TYPES.GET_INFO_FIRST_LOAD_FAILURE })
  }
}

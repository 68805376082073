import { put /* , call */ } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import LocalStorage from './storage'

export default function sagaRun ({ api }) {
  return function* ({ type, data, callback }) {
    const successType = `${type}_SUCCESS`
    const failureType = `${type}_FAILURE`

    try {
      yield put({ type: `${type}_REQUEST`, payload: data })
      const res = yield api(data)

      if (res.status === 200 || res === true) {
        yield put({ type: successType, data: res.data, payload: data })
        if (callback) {
          callback(successType, res.data)
        }
      } else {
        throw res.errors
      }
    } catch (res) {

      // HANDEL ERRelse
      if (res.status === 401) {
        LocalStorage.remove('IS_AUTHENTICATION')
        LocalStorage.remove('INFO_USER')
        LocalStorage.remove('ROLE_AUTH')
        LocalStorage.clear()
        // Notification.error('La session a expiré')
        // return yield put(push('/login'))
      }

      if (res.status === 404) {
        return yield put(push('/'))
      }
      yield put({ type: failureType, data: res.data, payload: data })
      if (callback) {
        callback(failureType, res.data)
      }
    }
  }
}

import { all, takeLatest } from 'redux-saga/effects'
import sagaRunning from '../../utils/sagaRunning'
import { TYPES } from '../actions'

import {
  supportBrochure,
  supportCity
} from '../../api/support'

export default function* watcher () {
  yield all([
    takeLatest(TYPES.SUPPORT_BROCHURE, sagaRunning({
      api: supportBrochure
    })),
    takeLatest(TYPES.SUPPORT_CITY, sagaRunning({
      api: supportCity
    }))
  ])
}




import { all, takeLatest } from 'redux-saga/effects'
import sagaRunning from '../../utils/sagaRunning'
import { TYPES } from '../actions'

import { getAllCenter, getCenterById } from '../../api/centers'

export default function* watcher () {
  yield all([
    takeLatest(TYPES.GET_ALL_CENTER, sagaRunning({ api: getAllCenter })),
    takeLatest(TYPES.GET_CENTER_BY_ID, sagaRunning({ api: getCenterById }))
  ])
}

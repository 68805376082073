import { all, takeLatest } from 'redux-saga/effects'
import sagaRunning from '../../utils/sagaRunning'
import { TYPES } from '../actions'
import {
  getAllSlide
} from '../../api/slide'

export default function* watcher () {
  yield all([
    takeLatest(TYPES.GET_ALL_SLIDE, sagaRunning({ api: getAllSlide }))
  ])
}

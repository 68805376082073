import { all, takeLatest } from 'redux-saga/effects'
import sagaRunning from '../../utils/sagaRunning'
import { TYPES } from '../actions'

import {
  getAllScheduleStudent,
  // getAgendaGroupId,
  getGroupMe,
  getNumberCommentStudent,
  getCommentGroupId,
  getAllGroupMe,
  getTransactionHistory
} from '../../api/schedule'
import { getGroupInfo } from 'api/schedule'

export default function* watcher () {
  yield all([
    takeLatest(TYPES.GET_ALL_SCHEDULE_STUDENT, sagaRunning({
      api: getAllScheduleStudent
    })),
    // takeLatest(TYPES.GET_AGENDA_GROUP_ID, sagaRunning({
    //   api: getAgendaGroupId
    // })),
    takeLatest(TYPES.GET_GROUP_ME, sagaRunning({
      api: getGroupMe
    })),
    takeLatest(TYPES.GET_NUMBER_COMMENT_STUDENT, sagaRunning({
      api: getNumberCommentStudent
    })),takeLatest(TYPES.GET_COMMENT_GROUP_ID, sagaRunning({
      api: getCommentGroupId
    })),
    takeLatest(TYPES.GET_ALL_GROUP_ME, sagaRunning({
      api: getAllGroupMe
    })),
    takeLatest(TYPES.GET_TRANSACTION_HISTORY, sagaRunning({
      api: getTransactionHistory
    })),
    takeLatest(TYPES.GET_GROUP_INFO, sagaRunning({
      api: getGroupInfo
    }))
  ])
}




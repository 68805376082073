import * as moment from 'moment'

export const convertCamelCase = (str) => {
  str = str.toLowerCase()
  let strRes = ''
  for (let i = 0; i < str.length; i++) {
    if (str[ i ] === '_') {
      strRes = strRes + str[ i + 1 ].toUpperCase()
      i++
    } else {
      strRes = strRes + str[ i ]
    }
  }
  return strRes
}

export const mockData = (data, error, condition = true) => new Promise((resolve, reject) => {
  setTimeout(() => {
    if (condition) {
      resolve(data)
    } else {
      reject(new Error(error))
    }
  }, 1000)
})

export const genId = () => Math.random().toString(36).substr(2, 9)

export const fullNameAccount = (str1, str2) => {
  const firstName = str1 ? (str1.charAt(0).toUpperCase() + str1.slice(1).toLowerCase()) : ''
  const lastName = str2 ? (str2.charAt(0).toUpperCase() + str2.slice(1).toLowerCase()) : ''
  return firstName + ' ' + lastName
}

export const getBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result)
  reader.onerror = (error) => reject(error)
})

export const getDate = (value) => {
  let date = new Date(value)
  let currentDay = date.getDay()
  let dayName = ''

  switch (currentDay) {
    case 0:
      dayName = 'Sun'
      break
    case 1:
      dayName = 'Mon'
      break
    case 2:
      dayName = 'Tue'
      break
    case 3:
      dayName = 'Wed'
      break
    case 4:
      dayName = 'Thu'
      break
    case 5:
      dayName = 'Fri'
      break
    case 6:
      dayName = 'Sat'
      break
    default:
      return ''
  }

  return dayName + ' ' + moment(value).format('DD.MM')
}

export const getBetweenTwoDate = (value) => {
  const currentDay = new Date()
  const prevTime = new Date(value)
  let diff = (currentDay.getTime() - prevTime.getTime()) / (1000 * 60 * 60 * 24)
  let betweenTwoDate = ''

  switch (parseInt(diff) % 10) {
    case 0:
      if (diff * 24 >= 1) {
        return betweenTwoDate = `${parseInt(diff * 24)} hours ago`
      }
      betweenTwoDate = `${parseInt(diff * 24 * 60)} minutes ago`
      break
    case 1:
      betweenTwoDate = '1 day ago'
      break
    case 2:
      betweenTwoDate = '2 day ago'
      break
    default:
      betweenTwoDate = moment(value).format('YYYY-MM-DD HH:mm:ss')
      break
  }
  return betweenTwoDate
}

export const getTime = (list , day) => {
  const ListDay = [
    { id: 0, value: 'Sun' },
    { id: 1, value: 'Mon' },
    { id: 2, value: 'Tue' },
    { id: 3, value: 'Wed' },
    { id: 4, value: 'Thu' },
    { id: 5, value: 'Fri' },
    { id: 6, value: 'Sat' }
  ]
  if (list && list.length > 0) {
    const a = ListDay.filter((x) => list.includes(x.id))
    const b = a.map(item => item.value).join(', ')
    return b
  } else {
    return moment(day && day).format('ddd')
  }
}

import { TYPES } from '../actions'
import constant from '../../constant'
import Configs from '../../configs/index'
const keyPayment = Configs.STRIPE_PUBLIC_KEY

const INIT_STATE = {
  loaded: [],
  submitting: null,
  error: null,

  keyPayment: keyPayment,
  itemsAmount: [],

  oneTime: [],
  recurring: []
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TYPES.GET_KEY_PAYMENT_REQUEST:
    case TYPES.REQUEST_PAYMENT_REQUEST:
      return {
        ...state,
        submitting: action.type
      }
    case TYPES.REQUEST_PAYMENT_SUCCESS:
      return {
        ...state,
        submitting: null,
        itemsAmount: [],
        oneTime: [],
        recurring: []
      }
    case TYPES.GET_KEY_PAYMENT_SUCCESS:
      return {
        ...state,
        submitting: null,
        // keyPayment: action.data?.publishableKey
        keyPayment
      }
    case TYPES.GET_KEY_PAYMENT_FAILURE:
    case TYPES.REQUEST_PAYMENT_FAILURE:
      return {
        ...state,
        submitting: null,
        error: action.error
      }
    case TYPES.SET_ITEMS_AMOUNT:
      return {
        ...state,
        submitting: null,
        itemsAmount: action.data
      }
    case TYPES.SET_DATA_PAYMENT: {
      let oneTime = [ ...state.oneTime ]
      let recurring = [ ...state.recurring ]
      const enrollId = action.data.result?.enrollStudents?.[0]?.enrollId
      const groupId = action.data.result?.groupId
      const productId = action.data.result?.productId
      if (action.data?.type === 'delete' && action.data?.enrollId) {
        oneTime =
          oneTime?.length > 0
            ? oneTime.filter((x) => x.enrollId !== action.data.enrollId)
            : []
        recurring =
          recurring?.length > 0
            ? recurring.filter((x) => x.enrollId !== action.data.enrollId)
            : []
      }
      if (action.data.type === 'update') {
        oneTime =
          oneTime?.length > 0
            ? oneTime.filter((x) => x.enrollId !== enrollId)
            : []
        recurring =
          recurring?.length > 0
            ? recurring.filter((x) => x.enrollId !== enrollId)
            : []
      }
      if (action.data.type !== 'delete') {
        if (action.data.result.paymentType === constant.PAYMENT_TYPE.ONE_TIME) {
          if (state?.oneTime?.length > 0) {
            if (
              !state.oneTime.some(
                (x) => x.enrollId === enrollId && x.groupId === groupId
              )
            ) {
              oneTime = [
                ...state.oneTime,
                {
                  enrollId,
                  groupId
                }
              ]
            }
          } else {
            oneTime = [
              {
                enrollId,
                groupId
              }
            ]
          }
        }
        if (
          action.data.result.paymentType === constant.PAYMENT_TYPE.RECURRING
        ) {
          if (state?.recurring?.length > 0) {
            if (
              !state.recurring.some(
                (x) => x.enrollId === enrollId && x.groupId === groupId
              )
            ) {
              recurring = [
                ...state.recurring,
                {
                  enrollId,
                  groupId,
                  productId
                }
              ]
            }
          } else {
            recurring = [
              {
                enrollId,
                groupId,
                productId
              }
            ]
          }
        }
      }
      return {
        ...state,
        submitting: null,
        oneTime,
        recurring
      }
    }
    default:
      return state
  }
}

export default [
  'LOGIN_CUSTOMER',
  'LOGIN_TEACHER',
  'REGISTER',
  'GOOGLE_ACCOUNT_IS_EXISTED',

  'GET_INFO_FIRST_LOAD',
  'LOGOUT',
  'GET_CUSTOMER_INFO',
  'ADD_STUDENT',
  'TEACHER_JOIN_US',
  'CHANGE_PASSWORD_CUSTOMER',

  'CREATE_APPOINTMENT_ONLINE',
  'GET_CALENDAR_APPOINTMENTS',

  'GET_TEACHER_INFO',
  'CHANGE_PASSWORD_TEACHER',
  'CHANGE_TEACHER_INFO',
  'HANDLE_SEND_MAIL',
  'RESET_PASSWORD',
  'CHECK_EMAIL',
  'GET_TIME_BOOKING',
  'SEND_MAIL_REGISTER',

  // CENTERS
  'GET_ALL_CENTER',
  'GET_CENTER_BY_ID',

  // SCHEDULE STUDENT
  'GET_ALL_SCHEDULE_STUDENT',
  'GET_GROUP_ME',
  'GET_NUMBER_COMMENT_STUDENT',
  'GET_COMMENT_GROUP_ID',

  // ENROLLS
  'ENROLLS_ADD_CART',
  'ENROLLS_GET_ALL',
  'ENROLLS_DELETE',
  'ENROLLS_UPDATE_PRICE',
  'GET_PAYMENTS_AMOUNT',
  'ENROLLS_PENDING',
  'CHECK_ENROLL_PAID',

  // PAYMENT
  'GET_KEY_PAYMENT',
  'REQUEST_PAYMENT',
  'SET_ITEMS_AMOUNT',
  'SET_DATA_PAYMENT',

  'CHANGE_CUSTOMER_INFO',
  'CHANGE_STUDENT_INFO',
  'CHOOSE_AVATAR',
  'SET_BACK_URL',

  //GROUP
  'GET_ALL_GROUP',
  'GET_ALL_SUBJECTS',
  'GET_SUBJECT_BY_ID',
  'GET_GROUP_BY_ID',
  'GET_GROUP_BY_USER',

  // Avariable - Content

  'GET_ALL_AVAILABLE',
  'GET_DETAIL_AVAILABLE',
  'GET_ALL_GROUP_ME',
  'GET_TRANSACTION_HISTORY',

  // Agenda
  'GET_AGENDA_GROUP_ID',
  'ADD_AGENDAS',
  'UPDATE_AGENDA_ID',
  'DELETE_AGENDA_ID',
  'ADD_COMMENT',
  'GET_COMMENTS_ID',

  //CONTENT
  'CREATE_CONTENT',
  'GET_ALL_CONTENT_BY_TEACHER',
  'DELETE_CONTENT',

  //SUPPORT
  'SUPPORT_BROCHURE',
  'SUPPORT_CITY',

  'GET_INFO_INVOICE',

  'GET_LIST_NOTIFICATION',
  'GET_NUMBER_NOTIFICATION',

  //SLIDE
  'GET_ALL_SLIDE',

  'GET_GROUP_INFO'
]

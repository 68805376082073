import { TYPES } from '../actions'

const INIT_STATE = {
  submitting: null,
  student: null,
  allGroup: [],
  subject: {},
  allSubject: [],
  groupChoose: {},
  customerMe: {},
  groupByUser: [],
  allContentByTeacher: [],
  // idContentdDelete: null
  isLoading: false
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TYPES.ADD_STUDENT_REQUEST:
    case TYPES.GET_ALL_SUBJECTS_REQUEST:
    case TYPES.GET_SUBJECT_BY_ID_REQUEST:
    case TYPES.GET_GROUP_BY_ID_REQUEST:
    case TYPES.GET_GROUP_BY_USER_REQUEST:
    case TYPES.CREATE_CONTENT_REQUEST:
    case TYPES.GET_ALL_CONTENT_BY_TEACHER_REQUEST:
    case TYPES.DELETE_CONTENT_REQUEST:
      return {
        ...state,
        submitting: action.type
      }
    case TYPES.GET_ALL_GROUP_REQUEST:
      return {
        ...state,
        isLoading: true,
        submitting: action.type
      }

    case TYPES.ADD_STUDENT_SUCCESS:
      return {
        ...state,
        submitting: null
      }

    case TYPES.CREATE_CONTENT_SUCCESS:
      return {
        ...state,
        submitting: null
      }

    case TYPES.GET_ALL_GROUP_SUCCESS:
      return {
        ...state,
        submitting: null,
        isLoading: false,
        allGroup: {
          count: action.data.count,
          rows: action.data.rows
        }
      }

    case TYPES.DELETE_CONTENT_SUCCESS:
      return {
        ...state,
        submitting: null
        // idContentdDelete: action.data,
        // allContentByTeacher: state.allContentByTeacher.filter(item => item.id !== action.data.id)
      }

    case TYPES.GET_GROUP_BY_ID_SUCCESS:
      return {
        ...state,
        submitting: null,
        groupChoose: action.data
      }

    case TYPES.GET_ALL_CONTENT_BY_TEACHER_SUCCESS:
      return {
        ...state,
        submitting: null,
        allContentByTeacher: action.data
      }

    case TYPES.GET_GROUP_BY_USER_SUCCESS:
      return {
        ...state,
        submitting: null,
        groupByUser: action.data
      }

    case TYPES.GET_ALL_SUBJECTS_SUCCESS:
      return {
        ...state,
        submitting: null,
        allSubject: action.data
      }
    case TYPES.GET_SUBJECT_BY_ID_SUCCESS:
      return {
        ...state,
        submitting: null,
        subject: action.data
      }
    case TYPES.ADD_STUDENT_FAILURE:
    case TYPES.GET_ALL_SUBJECTS_FAILURE:
    case TYPES.GET_SUBJECT_BY_ID_FAILURE:
    case TYPES.GET_ALL_GROUP_FAILURE:
    case TYPES.GET_GROUP_BY_ID_FAILURE:
    case TYPES.GET_GROUP_BY_USER_FAILURE:
    case TYPES.CREATE_CONTENT_FAILURE:
    case TYPES.GET_ALL_CONTENT_BY_TEACHER_FAILURE:
    case TYPES.DELETE_CONTENT_FAILURE:
      return {
        ...state,
        submitting: null,
        error: action.error
      }
    default:
      return state
  }
}

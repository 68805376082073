import { MainApi, PreSignedApi } from './endpoint'

export const loginCustomer = (payload) =>
  MainApi.post('/user/auth/customer/login', payload)

export const loginTeacher = (payload) =>
  MainApi.post('/user/auth/teacher/login', payload)

export const register = (payload) =>
  MainApi.post('/user/auth/register', payload)

export const teacherJoinUs = (payload) =>
  MainApi.post('/user/auth/teacher/register', payload)

export const logout = () => MainApi.post('/user/auth/logout')

export const getCustomerInfo = () => MainApi.get('/user/customers/me')

export const changePasswordCustomer = (payload) =>
  MainApi.post('/user/auth/customer/change-password', payload)

export const changeCustomerInfo = (payload) =>
  MainApi.put('/user/customers/me/parent', payload)

export const chooseAvatar = (payload) =>
  PreSignedApi.post('/services/aws/multiply-signs3', payload)

export const changeStudentInfo = (payload) =>
  MainApi.put(`/user/customers/me/student/${payload.id}`, {
    ...payload,
    id: undefined,
    customerId: undefined
  })

export const createAppointmentOnline = (payload) =>
  MainApi.post('/user/appointments', payload)

export const getCalendarAppointments = (payload) =>
  MainApi.get(`/user/appointments/${payload.id}`)

export const getTeacherInfo = () => MainApi.get('/user/teachers')

export const changePasswordTeacher = (payload) =>
  MainApi.put('/user/auth/teacher/change-password', payload)

export const changeTeacherInfo = (payload) =>
  MainApi.put('/user/teachers', payload)

export const handleSendMail = (payload) =>
  MainApi.post('/user/auth/send-email/forgot-password', payload)

export const resetPassword = (payload) =>
  MainApi.put('/user/auth/change-password-by-token', payload)

export const checkEmail = (payload) =>
  MainApi.post('/user/auth/is-existed', payload)

export const getTimeBooking = (payload) =>
  MainApi.get(`/user/appointments/${payload}`, payload)

export const sendMailRegister = (payload) =>
  MainApi.post('/user/auth/send-email/verify-email', payload)

export const googleAccountIsExisted = (payload) =>
  MainApi.post('/user/auth/is-existed', payload)
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import LocalStorage from 'utils/storage'
import { actions } from '../store/actions'

const { getInfoFirstLoad, checkEnrollPaid } = actions

function Settings (props) {
  const [ loading, setLoading ] = useState(true)
  const dispatch = useDispatch()
  
  useEffect(() => {
    dispatch(getInfoFirstLoad())
    if (LocalStorage.get('IS_AUTHENTICATION') || LocalStorage.get('INFO_USER')?.token) {
      dispatch(
        checkEnrollPaid(null, () => {
          setLoading(false)
        })
      )
    } else {
      setLoading(false)
    }
  }, [ dispatch ])

  const { children } = props
  return loading ? null : children
}

export default Settings

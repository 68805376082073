import { all, takeLatest } from 'redux-saga/effects'
import sagaRunning from '../../utils/sagaRunning'
import { TYPES } from '../actions'

import {
  getKeyPayment,
  requestPayment
} from '../../api/payment'

export default function* watcher () {
  yield all([
    takeLatest(TYPES.GET_KEY_PAYMENT, sagaRunning({ api: getKeyPayment })),
    takeLatest(TYPES.REQUEST_PAYMENT, sagaRunning({ api: requestPayment }))
  ])
}

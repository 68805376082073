export default {
  INVALID: 'Invalide',
  EMAIL_NOT_ACTIVATED: 'Courriel non activé',
  PHONE_NOT_ACTIVATED: 'Téléphone non activé',
  NOT_DEPOSIT: 'Pas de dépôt',
  EXISTED: 'Existé',
  NOT_CODE: 'Pas de code',
  EMAIL_NOT_FOUND: 'Email non trouvé',
  PASSWORD_WRONG: 'Mot de passe incorrect',
  CENTER_EXISTED: 'Le centre existait',
  EMAIL_EXISTED: 'L\'email existait',
  OLD_PASSWORD_NOT_MATCH: 'L\'ancien mot de passe ne correspond pas',
  STUDENT_NOT_FOUND: 'Étudiant non trouvé',
  PARENT_NOT_FOUND: 'Parent introuvable',
  ROOM_EXISTED: 'La chambre existait',
  TEACHER_NOT_FOUND: 'Professeur introuvable',
  SYSTEM_ERROR: 'Erreur système',
  ADMIN_EXISTED: 'L\'administrateur existait',
  ADMIN_NOT_FOUND: 'Administrateur introuvable',
  GROUP_NAME_EXISTED: 'Le nom du groupe existait',
  GROUP_START_DATE_MUST_GATHER_THAN_NOW: 'La date de début du groupe doit se réunir que maintenant',
  TIME_OVERLAP: 'Chevauchement temporel',
  SUBJECT_NOT_FOUND: 'Sujet introuvable',
  USER_NOT_FOUND: 'Utilisateur non trouvé',
  SUBJECT_EXISTED: 'Le sujet existait',
  CENTER_NOT_FOUND: 'Centre introuvable',
  STUDENT_COMPLICATED_OR_NOT_FOUND: 'Élève compliqué ou introuvable',
  FIRST_ITEM_REPEATED_ON_NEED_TO_EQUAL_DAY_OF_START_DATE: 'Premier élément répété au besoin pour égaler le jour de la date de début',
  GROUP_NOT_FOUND: 'Groupe introuvable',
  NUMBER_OF_STUDENT_MUST_BE_GATHER_THAN_REGISTERED: 'Le nombre d\'étudiants doit être rassemblé avant d\'être inscrit',
  START_DATE_NEED_TO_BETWEEN_OLD_RANGE: 'La date de début doit être comprise entre l\'ancienne plage',
  ROOM_NOT_FOUND: 'Chambre non trouvée',
  ROOM_NOT_BELONGS_TO_CENTER: 'La chambre n\'appartient pas au centre',
  USER_ENROLLED: 'Certains utilisateurs sont déjà inscrits',
  GROUP_ENROLL_ERROR: 'Le groupe est plein ou n\'existe pas',
  TYPE_GROUP_NOT_SAME_INITIATION: 'Type de groupe pas la même initiation',
  CART_EMPTY: 'Panier vide',
  NUMBER_OF_ENROLLED_GROUP_INCORRECT: 'Nombre de groupes inscrits incorrect',
  TEACHER_NOT_IN_CHARGE_OF_GROUP: 'Professeur non responsable du groupe',
  INCLUDE_INVALID_STUDENT: 'Inclure un étudiant invalide',
  RECEIVER_NOT_NULL: 'Récepteur non nul',
  COURSE_NOT_FOUND: 'Cours non trouvé',
  PAYMENT_ERROR: 'Erreur de paiement',
  FULL_SLOT: 'Emplacement complet de groupe',
  CALCULATE_AMOUNT_ERROR: 'Veuillez vérifier le prix du cours',
  INSERT_ATTACHMENT_FAIL: 'Échec de l\'insertion d\'une pièce jointe',
  INVALID_AMOUNT: 'Le montant doit être supérieur à 0',
  AMOUNT_NOT_NUMBER: 'Pas un nombre',
  EMAIL_OR_PHONE_IN_USE: 'Courriel ou téléphone déjà utilisé',
  VERIFY_CODE_INVALID: 'Vérifiez que le code n\'est pas valide',
  PRICE_NOT_UPDATED_ERROR: 'Le prix n\'a pas été mis à jour',
  CARD_DECLINE_ERROR: 'Erreur de refus de la carte',
  ENROLLS_ADD_CART_FAILURE: 'Créneau déjà pris',
  PAYMENT_CURRING: 'Courant de paiement',
  PAYMENT_ONETIME: 'Paiement ponctuel',
  INCLUDE_INVALID_ITEM: 'Inclure un élément non valide',
  ITEM_PAID_OR_INVALID: 'Ont été payés ou n\'existent pas',
  DURATION_TIME_30_MINUTES_AT_LEAST: 'Durée 30 minutes au moins',
  END_TIME_MUST_BE_GATHER_THAN_START_TIME: 'L\'heure de fin doit être supérieure à l\'heure de début',
  NUMBER_OF_STUDENT_MUST_BE_LESS_THAN_CAPACITY_OF_ROOM: 'Le nombre d\'étudiants doit être inférieur à la capacité de la salle',
  BROCHURE_NOT_FOUND: 'Brochure introuvable',
  FEEDBACK_NOT_FOUND: 'Commentaires non trouvés',
  ACCESS_DENIED: 'Accès refusé',
  NOT_FOUND: 'Pas trouvé',
  TIME_OUT: 'Temps mort',
  BAD_REQUEST: 'Mauvaise demande',
  NOT_AUTHENTICATE: 'Erreur de serveur interne',
  INTERNAL_SERVER_ERROR: 'Erreur de serveur interne',
  403: 'Accès refusé',
  404: 'Pas trouvé',
  402: 'Temps mort',
  400: 'Mauvaise demande',
  401: 'Erreur de serveur interne',
  500: 'Erreur de serveur interne',
  HAVE_BEEN_PAID_OR_NOT_EXIST: 'Les cours ont été payés ou n\'existent pas',
  AMOUNT_NOT_A_NUMBER: 'Montant et non numéro',
  CONFIRM_PAYMENT_FAIL: 'L\'authentification pour ce paiement a échoué',
  GROUP_FULL_SLOT: 'Groupe Plein Slot'
}

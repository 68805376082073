import { TYPES } from '../actions'

const INIT_STATE = {
  loaded: [],
  submitting: null,
  error: null,

  enrollList: [],
  enrollPending: null,
  amount: 0,

  backToUrl: null
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TYPES.ENROLLS_ADD_CART_REQUEST:
    case TYPES.ENROLLS_DELETE_REQUEST:
    case TYPES.ENROLLS_UPDATE_PRICE_REQUEST:
    case TYPES.GET_PAYMENTS_AMOUNT_REQUEST:
    case TYPES.ENROLLS_PENDING_REQUEST:
    case TYPES.CHECK_ENROLL_PAID_REQUEST:
      return {
        ...state,
        submitting: action.type
      }
    case TYPES.ENROLLS_GET_ALL_REQUEST:
      return {
        ...state,
        submitting: action.type,
        enrollList: []
      }
    case TYPES.ENROLLS_ADD_CART_SUCCESS:
      return {
        ...state,
        submitting: null
      }
    case TYPES.ENROLLS_UPDATE_PRICE_SUCCESS:
      const newEnrollList = state.enrollList.map((x) => {
        if (x.id === action.payload.enrollId) {
          const newEnroll = {
            ...x,
            priceId: action.data.priceId
          }
          return newEnroll
        }
        return x
      })
      return {
        ...state,
        submitting: null,
        enrollList: newEnrollList
      }
    case TYPES.ENROLLS_DELETE_SUCCESS:
      return {
        ...state,
        submitting: null,
        enrollList: state.enrollList.filter((x) => x.id !== action.payload.id),
        enrollPending: state.enrollPending.filter(
          (x) => x.id !== action.payload.id
        )
      }
    case TYPES.ENROLLS_GET_ALL_SUCCESS:
      return {
        ...state,
        submitting: null,
        enrollList: action.data
      }
    case TYPES.GET_PAYMENTS_AMOUNT_SUCCESS:
      return {
        ...state,
        submitting: null,
        amount: action.data
      }
    case TYPES.ENROLLS_PENDING_SUCCESS:
      return {
        ...state,
        submitting: null,
        enrollPending: action.data
      }
    case TYPES.CHECK_ENROLL_PAID_SUCCESS:
      return {
        ...state,
        submitting: null,
        enrollPaid: action.data.length !== 0
      }
    case TYPES.CHECK_ENROLL_PAID_FAILURE:
    case TYPES.ENROLLS_ADD_CART_FAILURE:
    case TYPES.ENROLLS_GET_ALL_FAILURE:
    case TYPES.ENROLLS_DELETE_FAILURE:
    case TYPES.ENROLLS_UPDATE_PRICE_FAILURE:
    case TYPES.GET_PAYMENTS_AMOUNT_FAILURE:
    case TYPES.ENROLLS_PENDING_FAILURE:
      return {
        ...state,
        submitting: null,
        error: action.error
      }
    case TYPES.SET_BACK_URL:
      return {
        ...state,
        submitting: null,
        backToUrl: action.data
      }
    default:
      return state
  }
}

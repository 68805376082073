// eslint-disable
import Axios from './axios'
import Configs from '../configs'

const API_URL = `${Configs.API_URL}`

class API {
  static get (endpoint, data) {
    return Axios.create({
      endpoint: `${API_URL}${endpoint}`,
      method: 'get',
      data
    })
  }

  static post (endpoint, data) {
    return Axios.create({
      endpoint: `${API_URL}${endpoint}`,
      method: 'post',
      data
    })
  }

  static put (endpoint, data) {
    return Axios.create({
      endpoint: `${API_URL}${endpoint}`,
      method: 'put',
      data
    })
  }

  static delete (endpoint) {
    return Axios.create({
      endpoint: `${API_URL}${endpoint}`,
      method: 'delete'
    })
  }
}

class MainApi extends API {}
class SignedApi {
  post (endpoint, payload) {
    return this.requestAPI(endpoint, payload, 'post')
  }

  put (endpoint, payload) {
    return this.requestAPI(endpoint, payload, 'put')
  }

  requestAPI = (endpoint, { files, handlePayload }, method) =>
    new Promise(async (resolve) => {
      const handlePayload = files.map((file) => ({
        fileName: file.name,
        fileType: file.type
      }))
      const resultSigned = await API[ method ](endpoint, {
        files: handlePayload
      })

      if (resultSigned.status !== 200) {
        return resolve(resultSigned)
      }

      if (resultSigned.status === 200) {
        const arrayPromise = []
        resultSigned.data.forEach((file, index) => {
          arrayPromise.push(
            new Promise((resolve) => {
              const xhr = new XMLHttpRequest()
              xhr.open('PUT', file.signedRequest)

              xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                  if (xhr.status === 200) {
                    const { responseURL } = xhr
                    const index = responseURL.indexOf('?')
                    const url = responseURL.substring(0, index)
                    resolve({
                      status: xhr.status,
                      url
                    })
                  } else {
                    resolve(null)
                  }
                }
              }

              xhr.send(files[ index ])
              resolve()
            })
          )
        })

        await Promise.all(arrayPromise)
        return resolve(resultSigned)
      }
    })
}

const PreSignedApi = new SignedApi()

export { MainApi, PreSignedApi }

import { TYPES } from '../actions'

const INIT_STATE = {
  submitting: null,
  error: null,
  slides: null,
  isRefresh: null
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TYPES.GET_ALL_SLIDE_REQUEST:
      return {
        ...state,
        submitting: action.type
      }

    case TYPES.GET_ALL_SLIDE_SUCCESS:
      return {
        ...state,
        submitting: null,
        slides: action.data.rows
      }

    case TYPES.GET_ALL_SLIDE_FAILURE:

      return {
        ...state,
        submitting: null,
        error: action.error
      }

    default:
      return state
  }
}

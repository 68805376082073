import { all, takeLatest, takeEvery } from 'redux-saga/effects'
import sagaRunning from '../../utils/sagaRunning'
import { TYPES } from '../actions'

import {
  enrollsAddCart,
  enrollsGetAll,
  enrollsDelete,
  enrollsUpdatePrice,
  getPaymentsAmount,
  enrollsPending,
  checkEnrollPaid
} from '../../api/enrolls'

export default function* watcher () {
  yield all([
    takeLatest(TYPES.ENROLLS_ADD_CART, sagaRunning({ api: enrollsAddCart })),
    takeLatest(TYPES.ENROLLS_GET_ALL, sagaRunning({ api: enrollsGetAll })),
    takeLatest(TYPES.ENROLLS_DELETE, sagaRunning({ api: enrollsDelete })),
    takeEvery(TYPES.ENROLLS_UPDATE_PRICE, sagaRunning({ api: enrollsUpdatePrice })),
    takeEvery(TYPES.GET_PAYMENTS_AMOUNT, sagaRunning({ api: getPaymentsAmount })),
    takeLatest(TYPES.ENROLLS_PENDING, sagaRunning({ api: enrollsPending })),
    takeLatest(TYPES.CHECK_ENROLL_PAID, sagaRunning({ api: checkEnrollPaid }))
  ])
}

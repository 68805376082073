import { all, takeLatest } from 'redux-saga/effects'
import sagaRunning from 'utils/sagaRunning'
import { TYPES } from 'store/actions'

import { getInvoiceTeacher } from 'api/teacher-invoice'

export default function* watcher () {
  yield all([
    takeLatest(TYPES.GET_INFO_INVOICE, sagaRunning({ api: getInvoiceTeacher }))
  ])
}

import { TYPES } from '../actions'

const INIT_STATE = {
  loaded: [],
  submitting: null,
  error: null
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TYPES.SUPPORT_BROCHURE_REQUEST:
    case TYPES.SUPPORT_CITY_REQUEST:
      return {
        ...state,
        submitting: action.type
      }
    case TYPES.SUPPORT_BROCHURE_SUCCESS:
    case TYPES.SUPPORT_CITY_SUCCESS:
      return {
        ...state,
        submitting: null
      }
    case TYPES.SUPPORT_BROCHURE_FAILURE:
    case TYPES.SUPPORT_CITY_FAILURE:
      return {
        ...state,
        submitting: null,
        error: action.error
      }
    default:
      return state
  }
}

import { all, takeLatest } from 'redux-saga/effects'
import sagaRunning from '../../utils/sagaRunning'
import { TYPES } from '../actions'

import {
  getListNotification,
  getNumberNotification
} from '../../api/notification'

export default function* watcher () {
  yield all([
    takeLatest(TYPES.GET_LIST_NOTIFICATION, sagaRunning({ api: getListNotification })),
    takeLatest(TYPES.GET_NUMBER_NOTIFICATION, sagaRunning({ api: getNumberNotification }))
  ])
}

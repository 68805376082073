import { MainApi } from './endpoint'

const url = '/user'

export const getAgendaGroupId = (payload) => {
  const handlePayload = { ...payload }
  delete handlePayload.id

  return MainApi.get(`${url}/agendas/group/${payload.id}`, handlePayload)
}
export const addAgendas = ({ groupId, data }) => MainApi.post(`${url}/agendas/group/${groupId}`, data)
export const updateAgendaId = ({ groupId, data }) => MainApi.put(`${url}/agendas/group/${groupId}`, data)
export const deleteAgendaId = (payload) => MainApi.delete(`${url}/agendas/${payload}`)
export const addComment = (payload) => MainApi.post(`${url}/comments`,payload)
export const getCommentsId = (payload) => MainApi.get(`${url}/comments/group/${payload.groupId}?studentId=${payload.studentId}`)

import SUCCESS from './message-success'
import ERROR from './message-error'

export default {
  IS_NOT_LOGIN: 'IS_NOT_LOGIN',

  ROLE_AUTH: {
    CUSTOMER: 'customer',
    TEACHER: 'teacher'
  },

  UNIT_PRICE: '€',

  PAYMENT_TYPE: {
    ONE_TIME: 'ONE_TIME',
    RECURRING: 'RECURRING'
  },

  AUTH: {
    STUDENT: 'STUDENT',
    TEACHER: 'TEACHER'
  },

  CHOOSE_GROUP: {
    ONLINE: 'ONLINE',
    OFFLINE: 'OFFLINE'
  },

  HEIGHT_HOUR: 92,

  TYPE_GROUP: {
    INTERNSHIP: 'INTERNSHIP',
    HOLIDAY: 'HOLIDAY',
    NORMAL: 'NORMAL'
  },

  LEVEL: {
    6: '6',
    5: '5',
    4: '4',
    3: '3',
    2: '2',
    1: '1',
    TERMINAL: 'TERMINAL'
  },

  FILE_TYPES: [
    'image/jpeg',
    'image/gif',
    'image/png',
    'application/vnd.ms-excel',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/csv',
    'text/plain',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/pdf'
  ]
}

export { SUCCESS, ERROR }

export const LEVEL = [
  { name: '6ème', value: '6' },
  { name: '5ème', value: '5' },
  { name: '4ème', value: '4' },
  { name: '3ème', value: '3' },
  { name: '2nd', value: '2' },
  { name: '1ère', value: '1' },
  { name: 'Terminal', value: 'TERMINAL' }
]

export const TYPE_GROUP = [
  { name: 'INTERNSHIP', value: 'INTERNSHIP' },
  { name: 'HOLIDAY', value: 'HOLIDAY' },
  { name: 'NORMAL', value: 'NORMAL' }
]

export const TYPE_NOTIFICATION = {
  PAYMENT: 'PAYMENT',
  COMMENT: 'COMMENT',
  CONTENT: 'CONTENT'
}

export const LIMIT = 10

export const FB = 'https://facebook.com/ExcellenceSoutienScolaire'

export const IS = 'https://instagram.com/excellencesoutienscolaire'

export const ERROR_AVAILABLE = 'ERROR_AVAILABLE'

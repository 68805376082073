import { TYPES } from '../actions'

const initialState = {
  infoInvoice: {},
  error: '',
  isLoading: false
}
export default (state = initialState, { type, data }) => {
  switch (type) {
    case TYPES.GET_INFO_INVOICE_REQUEST: {
      return {
        ...state,
        isLoading: true
      }
    }
    case TYPES.GET_INFO_INVOICE_SUCCESS: {
      return {
        ...state,
        infoInvoice: data,
        isLoading: false
      }
    }

    case TYPES.GET_INFO_INVOICE_FAILURE: {
      return {
        ...state,
        error: '',
        isLoading: false
      }
    }
    default:
      return state
  }
}

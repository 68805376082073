import React, { Suspense, lazy } from 'react'
import { Switch, Route, Redirect, BrowserRouter as Router } from 'react-router-dom'
import Loading from '../components/loading/index'
import LocalStorage from '../utils/storage'

import 'antd/es/layout/style/css'

import { handleAxiosApi } from '../api/interceptors'

handleAxiosApi()

const Login = lazy(() => import('../pages/account/login'))
const Registration = lazy(() => import('../pages/account/registration'))
const ForgotPassword = lazy(() => import('../pages/account/forgot-password'))
// const AboutUs = lazy(() => import('../pages/about-us'))
const AppointmentBooking = lazy(() => import('../pages/appointment-booking'))
const Home = lazy(() => import('../pages/home'))
const RegistrationOnline = lazy(() => import('../pages/registration-online'))
const TeacherJoinUs = lazy(() => import('../pages/teacher-join-us'))
const Settings = lazy(() => import('../pages/settings'))
const NotFound = lazy(() => import('../pages/not-found'))
const PlanSub = lazy(() => import('../pages/plan-subcription'))
const Contract = lazy(() => import('../pages/contract'))
const Payment = lazy(() => import('../pages/payment'))
const Schedule = lazy(() => import('../pages/schedule'))
const AddStudent = lazy(() => import('../pages/add-student'))
const Holiday = lazy(() => import('../pages/holiday'))
const AvailableContent = lazy(() => import('../pages/available-content'))
const ContentDetail = lazy(() => import('../pages/content-detail'))
const Internship = lazy(() => import('../pages/internship'))
const ManagementPending = lazy(() => import('../pages/management-pending'))
const ChangePassword = lazy(() => import('../pages/account/change-password'))
const YourProFile = lazy(() => import('../pages/account/your-profile'))
const StudentsInfo = lazy(() => import('../pages/account/students-info'))
const TeacherSchedule = lazy(() => import('../pages/teacher-schedule'))
const LessonInfo = lazy(() => import('../pages/lesson-info'))
const CreateContent = lazy(() => import('../pages/create-content'))
const InvoiceInfo = lazy(() => import('../pages/invoice-info'))
const ChooseGroup = lazy(() => import('../pages/choose-group'))
const ResetPassword = lazy(() => import('../pages/account/reset-password'))
const ManagementContent = lazy(() => import('../pages/management-content'))
const StudentNotification = lazy(() => import('../pages/student-notification'))
const TeacherNotification = lazy(() => import('../pages/teacher-notification'))
const Comments = lazy(() => import('../pages/comments'))
const Blog = lazy(() => import('../pages/blog'))
const BlogDetail = lazy(() => import('../pages/blog-detail'))

const PrivateRoute = ({ condition, redirect, ...props }) => {
  condition = condition()

  if (condition) {
    return <Route {...props} />
  }
  return <Redirect to={redirect} />
}

const Routes = () => {
  const _renderLazyComponent = (LazyComponent, params) => (props) => (
    <LazyComponent {...props} {...params} />
  )
  const _renderAuthRoutes = () => (
    <>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route
            exact
            path="/teacher/schedule"
            component={_renderLazyComponent(TeacherSchedule)}
          />
          <Route
            exact
            path="/create-content"
            component={_renderLazyComponent(CreateContent)}
          />
          <Route
            exact
            path="/lesson/:id"
            component={_renderLazyComponent(LessonInfo)}
          />

          <Route
            exact
            path="/change-password"
            component={_renderLazyComponent(ChangePassword)}
          />
          <Route
            exact
            path="/your-profile"
            component={_renderLazyComponent(YourProFile)}
          />
          <Route
            exact
            path="/invoice-info"
            component={_renderLazyComponent(InvoiceInfo)}
          />
          <Route
            exact
            path="/settings"
            component={_renderLazyComponent(Settings)}
          />
          <Route
            exact
            path="/not-found"
            component={_renderLazyComponent(NotFound)}
          />
          <Route
            exact
            path="/plan-sub"
            component={_renderLazyComponent(PlanSub)}
          />
          <Route
            exact
            path="/payment"
            component={_renderLazyComponent(Payment)}
          />
          <Route
            exact
            path="/management-pending/:id"
            component={_renderLazyComponent(ManagementPending)}
          />
          <Route
            exact
            path="/management-content"
            component={_renderLazyComponent(ManagementContent)}
          />
          {/* <Route
            exact
            path="/your-profile"
            component={_renderLazyComponent(YourProFile)}
          /> */}
          <Route
            exact
            path="/students-info"
            component={_renderLazyComponent(StudentsInfo)}
          />
          <Route
            exact
            path="/teacher/schedule"
            component={_renderLazyComponent(TeacherSchedule)}
          />
          <Route
            exact
            path="/teacher/notification"
            component={_renderLazyComponent(TeacherNotification)}
          />
          <Route
            exact
            path="/lesson/:id"
            component={_renderLazyComponent(LessonInfo)}
          />
          <Route
            exact
            path="/create-content"
            component={_renderLazyComponent(CreateContent)}
          />
          <Route
            exact
            path="/schedule"
            component={_renderLazyComponent(Schedule)}
          />
          <Route
            exact
            path="/schedule/notification"
            component={_renderLazyComponent(StudentNotification)}
          />
          <Route
            exact
            path="/add-student"
            component={_renderLazyComponent(AddStudent)}
          />
          <Route
            exact
            path="/holiday"
            component={_renderLazyComponent(Holiday)}
          />
          <Route
            exact
            path="/available-content"
            component={_renderLazyComponent(AvailableContent)}
          />
          <Route
            exact
            path="/available-content/:id"
            component={_renderLazyComponent(AvailableContent)}
          />
          <Route
            exact
            path="/available-detail/:id"
            component={_renderLazyComponent(ContentDetail)}
          />
          <Route
            exact
            path="/internships"
            component={_renderLazyComponent(Internship)}
          />
          <Route
            exact
            path="/contract"
            component={_renderLazyComponent(Contract)}
          />
          <Route
            exact
            path="/comment"
            component={_renderLazyComponent(Comments)}
          />
          {/*<Route
            exact
            path="/registration-online"
            component={_renderLazyComponent(RegistrationOnline)}
          />*/}
          <Redirect to="/not-found" />
        </Switch>
      </Suspense>
    </>
  )
  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route exact path="/" component={_renderLazyComponent(Home)} />
          <Route
            exact
            path="/login"
            component={_renderLazyComponent(Login)}
          />
          {/* flow registration */}
          <Route
            exact
            path="/registration"
            component={_renderLazyComponent(Registration)}
          />
          <Route
            exact
            path="/choose-group"
            component={_renderLazyComponent(ChooseGroup)}
          />
          <Route
            exact
            path="/appointment-booking"
            component={_renderLazyComponent(AppointmentBooking)}
          />
          {/* end flow registration */}
          <Route
            exact
            path="/forgot-password"
            component={_renderLazyComponent(ForgotPassword)}
          />
          {/* <Route
            exact
            path="/about-us"
            component={_renderLazyComponent(AboutUs)}
          /> */}
          <Route
            exact
            path="/reset-password/:email/:token/:type"
            component={_renderLazyComponent(ResetPassword)}
          />
          <Route
            exact
            path="/teacher-join-us"
            component={_renderLazyComponent(TeacherJoinUs)}
          />
          <Route
            exact
            path="/not-found"
            component={_renderLazyComponent(NotFound)}
          />
          <Route
            exact
            path="/registration-online"
            component={_renderLazyComponent(RegistrationOnline)}
          />
          <Route
            exact
            path="/blog/:slug"
            component={_renderLazyComponent(BlogDetail)}
          />
          <Route
            exact
            path="/blog"
            component={_renderLazyComponent(Blog)}
          />
          <PrivateRoute
            condition={() =>
              LocalStorage.has('IS_AUTHENTICATION') ||
              LocalStorage.has('INFO_USER')
            }
            redirect="/"
            path="/"
            component={_renderAuthRoutes}
          />
        </Switch>
      </Suspense>
    </Router>
  )
}

export default Routes

export default {
  LOGIN_CUSTOMER_SUCCESS: 'Connexion client réussie',
  LOGIN_TEACHER_SUCCESS: 'Connexion réussite des enseignants',
  REGISTER_SUCCESS: 'Inscription réussie',
  ADD_STUDENT_SUCCESS: 'Ajouter la réussite des élèves',
  CHANGE_PASSWORD_CUSTOMER_SUCCESS: 'Changer le succès du mot de passe client',
  CHANGE_PASSWORD_TEACHER_SUCCESS: 'Changer le succès du professeur de mot de passe',
  CHANGE_TEACHER_INFO_SUCCESS: 'Changer le succès des informations sur l\'enseignant',
  RESET_PASSWORD_SUCCESS: 'Réinitialiser le succès du mot de passe',
  GET_TIME_BOOKING_SUCCESS: 'Obtenez le succès de la réservation de temps',
  SEND_MAIL_REGISTER_SUCCESS: 'Envoyer le code avec succès',
  // ENROLLS_ADD_CART_SUCCESS: 'Les inscriptions ajoutent du succès au panier',
  ENROLLS_ADD_CART_SUCCESS: 'Groupe sélectionné avec Succès',
  ENROLLS_DELETE_SUCCESS: 'Inscrit le succès de la suppression',
  ENROLLS_UPDATE_PRICE_SUCCESS: 'Inscription mise à jour du succès des prix',
  CHANGE_CUSTOMER_INFO_SUCCESS: 'Changer le succès des informations client',
  CHANGE_STUDENT_INFO_SUCCESS: 'Changer la réussite des informations sur les étudiants',
  CHOOSE_AVATAR_SUCCESS: 'Choisissez le succès de l\'avatar',
  GET_AGENDA_GROUP_ID_SUCCESS: 'Obtenir le succès de l\'identifiant du groupe d\'agenda',
  ADD_AGENDAS_SUCCESS: 'Ajouter le succès des agendas',
  UPDATE_AGENDA_ID_SUCCESS: 'Mettre à jour le succès de l\'agenda',
  DELETE_AGENDA_ID_SUCCESS: 'Supprimer l\'agenda réussi',
  ADD_COMMENT_SUCCESS: 'Ajouter un commentaire avec succès',
  CREATE_CONTENT_SUCCESS: 'Créer le succès du contenu',
  GET_ALL_CONTENT_BY_TEACHER_SUCCESS: 'Obtenez tout le contenu par la réussite des enseignants',
  DELETE_CONTENT_SUCCESS: 'Succès de Delete_content',
  SUPPORT_BROCHURE_SUCCESS: 'Soutenir le succès de la brochure',
  SUPPORT_CITY_SUCCESS: 'Soutenir le succès de la ville'
}
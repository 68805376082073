// import '../index.css';
import 'antd/dist/antd.css'
import React from 'react'
import ReactDOM from 'react-dom'
import '../App.css'
import '../languages/index'
import Store from '../store'
import Routes from './routes'
// import { handleAxiosApi } from '../api/interceptors';
import Settings from './settings'

// handleAxiosApi();

ReactDOM.render(
  <Store>
    <Settings>
      <Routes />
    </Settings>
  </Store>,
  document.getElementById('root')
)


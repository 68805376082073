import { all } from 'redux-saga/effects'

import account from './account'
import schedule from './schedule'
import customer from './customer'
import enrrols from './enrolls'
import centers from './centers'
import available from './available'
import payment from './payment'
import agenda from './agenda'
import support from './support'
import teacherInvoice from './teacher-invoice'
import notification from './notification'
import slide from './slide'
export default function* sagas () {
  yield all([
    account(),
    schedule(),
    customer(),
    enrrols(),
    centers(),
    available(),
    payment(),
    agenda(),
    support(),
    teacherInvoice(),
    notification(),
    slide()
  ])
}

import Axios from 'axios'
import LocalStorage from '../utils/storage'
import qs from 'qs'

class Request {
  static async create ({ endpoint, method, data }) {
    const token = LocalStorage.get('INFO_USER')?.token
    const config = {
      baseURL: endpoint,
      method: method,
      headers: {
        'Content-Type': 'application/json'
      },
      paramsSerializer: (params) => qs.stringify(params)
    }

    if (token) {
      config.headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }

    if (method === 'get') {
      config.params = data
    } else {
      config.data = data
    }

    try {
      const res = await Axios(config)
      return res
    } catch (err) {
      return {
        success: false,
        message: 'Error system',
        errors: err
      }
    }
  }
}

export default Request

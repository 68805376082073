import { TYPES } from '../actions'

const INIT_STATE = {
  loaded: [],
  submitting: null,
  error: null,

  listAgenda: null,
  listComment: null
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TYPES.GET_AGENDA_GROUP_ID_REQUEST:
    case TYPES.ADD_AGENDAS_REQUEST:
    case TYPES.UPDATE_AGENDA_ID_REQUEST:
    case TYPES.UPDATE_AGENDA_ID_SUCCESS:
    case TYPES.DELETE_AGENDA_ID_REQUEST:
    case TYPES.DELETE_AGENDA_ID_SUCCESS:
    case TYPES.ADD_COMMENT_REQUEST:
    case TYPES.ADD_COMMENT_SUCCESS:
    case TYPES.GET_COMMENTS_ID_REQUEST:
      return {
        ...state,
        submitting: action.type
      }
    case TYPES.GET_AGENDA_GROUP_ID_SUCCESS:
      return {
        ...state,
        submitting: null,
        listAgenda: action.data.rows
      }
    case TYPES.ADD_AGENDAS_SUCCESS:
      return {
        ...state,
        submitting: null
      }
    case TYPES.GET_COMMENTS_ID_SUCCESS:
      return {
        ...state,
        submitting: null,
        listComment: action.data
      }
    case TYPES.GET_AGENDA_GROUP_ID_FAILURE:
    case TYPES.ADD_AGENDAS_FAILURE:
    case TYPES.UPDATE_AGENDA_ID_FAILURE:
    case TYPES.DELETE_AGENDA_ID_FAILURE:
    case TYPES.ADD_COMMENT_FAILURE:
    case TYPES.GET_COMMENTS_ID_FAILURE:
      return {
        ...state,
        submitting: null,
        error: action.error
      }
    default:
      return state
  }
}

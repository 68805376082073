import { TYPES } from '../actions'

const INIT_STATE = {
  loaded: [],
  submitting: null,
  error: null,

  allCenter: [],
  center: {}
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TYPES.GET_ALL_CENTER_REQUEST:
    case TYPES.GET_CENTER_BY_ID_REQUEST:
      return {
        ...state,
        submitting: action.type
      }
    case TYPES.GET_ALL_CENTER_SUCCESS: 
      return {
        ...state,
        submitting: null,
        allCenter: action.data.rows
      }
    case TYPES.GET_CENTER_BY_ID_SUCCESS:
      return {
        ...state,
        submitting: null,
        center: action.data
      }
    case TYPES.GET_ALL_CENTER_FAILURE:
    case TYPES.GET_CENTER_BY_ID_FAILURE:
      return {
        ...state,
        submitting: null,
        error: action.error
      }
    default:
      return state
  }
}

import { all, takeLatest } from 'redux-saga/effects'
import sagaRunning from 'utils/sagaRunning'
import { TYPES } from 'store/actions'

import {
  getAllAvailable,
  getDetailAvailable
} from './../../api/available-content'

export default function* watcher () {
  yield all([
    takeLatest(TYPES.GET_ALL_AVAILABLE, sagaRunning({ api: getAllAvailable })),
    takeLatest(
      TYPES.GET_DETAIL_AVAILABLE,
      sagaRunning({ api: getDetailAvailable })
    )
  ])
}

import { combineReducers } from 'redux'
import { TYPES } from '../actions/index'
import { connectRouter } from 'connected-react-router'
import account from './account'
import schedule from './schedule'
import customer from './customer'
import enrolls from './enrolls'
import centers from './centers'
import payment from './payment'
import available from './available'
import agenda from './agenda'
import support from './support'
import teacherInvoice from './teacher-invoice'
import notification from './notification'
import slide from './slide'

const appReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    account,
    schedule,
    customer,
    enrolls,
    centers,
    payment,
    available,
    agenda,
    support,
    teacherInvoice,
    notification,
    slide
  })

export default (history) => (state, action) => {
  if (action.type === TYPES.CLEAR_STORE) {
    state = {
      // localize: state.localize
    }
  }

  return appReducer(history)(state, action)
}

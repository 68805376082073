import { TYPES } from '../actions'
import { ERROR_AVAILABLE } from 'constant'

const initialState = {
  listAvailableContent: [],
  error: '',
  isLoading: false,
  availableDetails: {},
  files: '',
  submitting: null
}
export default (state = initialState, { type, data }) => {
  switch (type) {
    case TYPES.GET_ALL_AVAILABLE_REQUEST:
    case TYPES.GET_DETAIL_AVAILABLE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        submitting: type
      }
    }
    case TYPES.GET_ALL_AVAILABLE_SUCCESS: {
      return {
        ...state,
        listAvailableContent: data,
        isLoading: false,
        submitting: null
      }
    }

    case TYPES.GET_DETAIL_AVAILABLE_SUCCESS: {
      return {
        ...state,
        availableDetails: data,
        isLoading: false
      }
    }

    case TYPES.GET_ALL_AVAILABLE_FAILURE:
    case TYPES.GET_DETAIL_AVAILABLE_FAILURE: {
      return {
        ...state,
        error: data.message,
        availableDetails: ERROR_AVAILABLE,
        isLoading: false,
        submitting: null
      }
    }
    default:
      return state
  }
}
